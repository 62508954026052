<template>
  <v-card elevation="0" class="px-8">
    <v-card-title class="text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold">
      Welcome Back
    </v-card-title>
    <v-card-text>
      <div class="google__signup">
        <v-btn
          width="100%"
          @click.prevent="signInWithGoogle"
          elevation="0"
          class="py-5 text-none"
          style="border: 1px solid #c4c4c4; background: white"
        >
          <span
            class="pt-2 pr-3 text-subtitle-1"
            v-html="coloredGoogleIcon"
          ></span>
          Log In with Google
        </v-btn>
      </div>
      <div class="divider py-6">OR</div>
      <v-form v-model="loginForm" ref="loginForm" lazy-validation>
        <v-text-field
          id="companyEmail"
          background-color="white"
          placeholder="Company Email"
          v-model="companyEmail"
          :rules="emailRules"
        >
        </v-text-field>
        <v-text-field
          id="password"
          background-color="white"
          placeholder="Password"
          v-model="password"
          :rules="[(v) => !!v || 'Password needed']"
          :type="visiblePass ? 'text' : 'password'"
        >
          <v-icon
            @click.prevent="visiblePass = !visiblePass"
            slot="append"
            v-if="!visiblePass"
            color="primary"
          >
            {{ mdiEye }}
          </v-icon>

          <v-icon
            @click.prevent="visiblePass = !visiblePass"
            slot="append"
            v-else
            color="primary"
          >
            {{ mdiEyeOff }}
          </v-icon>
        </v-text-field>
        <div class="d-flex flex-row flex-row-reverse">
          <router-link to="/forgotPassword" style="color: #c4c4c4">
            <span> Forgot Password? </span>
          </router-link>
        </div>
      </v-form>
      <v-card-actions class="flex-column">
        <v-btn
          id="login"
          @click.prevent="validateloginForm"
          class="rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6"
          width="100%"
          color="#2EAC68"
          :disabled="!loginForm"
          :loading="submitted"
        >
          Log In
        </v-btn>
        <div
          class="align-self-start mt-8"
          v-if="whitelabelConfig.name === 'default'"
        >
          New to CodeDrills Hiring?
          <router-link to="/signup">Sign Up.</router-link>
        </div>
        <div class="align-self-start mt-8" v-else>
          New customer?
          <router-link to="/signup">Sign Up.</router-link>
        </div>
        <div
          class="align-self-start mt-8"
          v-if="whitelabelConfig.enableCommunity"
        >
          Looking for community account?
          <router-link to="/community/login">Login to community.</router-link>
        </div>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiGoogle, mdiEye, mdiEyeOff } from '@mdi/js'
import firebase from 'firebase'
import { mapActions } from 'vuex'
import { getWhitelabelConfig } from '../../utils/whitelabel'

export default {
  computed: {
    whitelabelConfig() {
      return getWhitelabelConfig()
    },
  },
  methods: {
    ...mapActions('customer', ['customerSignUp']),
    validateloginForm() {
      this.$refs.loginForm.validate() && this.doLogIn()
    },
    signInWithGoogle() {
      this.submitted = true
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          // The signed-in user info.
          const user = result.user
          this.$store.dispatch('notifs/addNotif', {
            text: `Successfully Logged In!`,
            type: 'success',
          })
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code
          const errorMessage = error.message
          // The email of the user's account used.
          const email = error.email
          console.log(`error: gsign:`, error.message, error)
          this.$store.dispatch('notifs/addNotif', {
            text: error.message,
            type: 'error',
          })
          this.submitted = false
        })
    },
    doLogIn() {
      this.submitted = true
      firebase
        .auth()
        .signInWithEmailAndPassword(this.companyEmail, this.password)
        .then((res) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Successfully Logged In!`,
            type: 'success',
          })
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          this.$store.dispatch('notifs/addNotif', {
            text: error?.message ?? 'Some error occurred, please try again!',
            type: 'error',
          })
          this.submitted = false
        })
    },
    redirectToCommunityLogin() {
      this.$router.push({
        path: '/community/login',
        query: { ...this.$route.query },
      })
    },
  },
  mounted() {
    console.log(this.$route.query)
    var mode = this.$route.query.mode
    if (!getWhitelabelConfig().enableRecruiter) {
      this.redirectToCommunityLogin()
      return
    }
    if (mode === 'resetPassword' || mode === 'verifyEmail') {
      if (this.$route.query.apiKey && this.$route.query.oobCode) {
        // should be reset password page when made
        this.redirectToCommunityLogin()
        return
      }
    }
  },
  data() {
    return {
      mdiGoogle,
      mdiEye,
      mdiEyeOff,
      coloredGoogleIcon:
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="36" height="36" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><rect x="0" y="0" width="48" height="48" fill="none" stroke="none" /><path fill="#FFC107" d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"/><path fill="#FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691z"/><path fill="#4CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"/><path fill="#1976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"/></svg>',
      loginForm: false,
      companyEmail: '',
      password: '',
      visiblePass: false,
      emailRules: [
        (v) => !!v || 'Email needed',
        (v) => /.+@.+/.test(v) || 'Email must be valid',
      ],
      submitted: false,
    }
  },
}
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: row;
  color: #909090;
}
.divider:before,
.divider:after {
  content: '-';
  margin: auto;
}
.divider:before {
  margin-right: 10px;
}
.divider:after {
  margin-left: 10px;
}
a {
  color: #2eac68;
  text-decoration-line: none;
}
</style>
