var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-8",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold"
  }, [_vm._v(" Welcome Back ")]), _c('v-card-text', [_c('div', {
    staticClass: "google__signup"
  }, [_c('v-btn', {
    staticClass: "py-5 text-none",
    staticStyle: {
      "border": "1px solid #c4c4c4",
      "background": "white"
    },
    attrs: {
      "width": "100%",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.signInWithGoogle.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "pt-2 pr-3 text-subtitle-1",
    domProps: {
      "innerHTML": _vm._s(_vm.coloredGoogleIcon)
    }
  }), _vm._v(" Log In with Google ")])], 1), _c('div', {
    staticClass: "divider py-6"
  }, [_vm._v("OR")]), _c('v-form', {
    ref: "loginForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.loginForm,
      callback: function ($$v) {
        _vm.loginForm = $$v;
      },
      expression: "loginForm"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "companyEmail",
      "background-color": "white",
      "placeholder": "Company Email",
      "rules": _vm.emailRules
    },
    model: {
      value: _vm.companyEmail,
      callback: function ($$v) {
        _vm.companyEmail = $$v;
      },
      expression: "companyEmail"
    }
  }), _c('v-text-field', {
    attrs: {
      "id": "password",
      "background-color": "white",
      "placeholder": "Password",
      "rules": [function (v) {
        return !!v || 'Password needed';
      }],
      "type": _vm.visiblePass ? 'text' : 'password'
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, [!_vm.visiblePass ? _c('v-icon', {
    attrs: {
      "slot": "append",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visiblePass = !_vm.visiblePass;
      }
    },
    slot: "append"
  }, [_vm._v(" " + _vm._s(_vm.mdiEye) + " ")]) : _c('v-icon', {
    attrs: {
      "slot": "append",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visiblePass = !_vm.visiblePass;
      }
    },
    slot: "append"
  }, [_vm._v(" " + _vm._s(_vm.mdiEyeOff) + " ")])], 1), _c('div', {
    staticClass: "d-flex flex-row flex-row-reverse"
  }, [_c('router-link', {
    staticStyle: {
      "color": "#c4c4c4"
    },
    attrs: {
      "to": "/forgotPassword"
    }
  }, [_c('span', [_vm._v(" Forgot Password? ")])])], 1)], 1), _c('v-card-actions', {
    staticClass: "flex-column"
  }, [_c('v-btn', {
    staticClass: "rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6",
    attrs: {
      "id": "login",
      "width": "100%",
      "color": "#2EAC68",
      "disabled": !_vm.loginForm,
      "loading": _vm.submitted
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.validateloginForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Log In ")]), _vm.whitelabelConfig.name === 'default' ? _c('div', {
    staticClass: "align-self-start mt-8"
  }, [_vm._v(" New to CodeDrills Hiring? "), _c('router-link', {
    attrs: {
      "to": "/signup"
    }
  }, [_vm._v("Sign Up.")])], 1) : _c('div', {
    staticClass: "align-self-start mt-8"
  }, [_vm._v(" New customer? "), _c('router-link', {
    attrs: {
      "to": "/signup"
    }
  }, [_vm._v("Sign Up.")])], 1), _vm.whitelabelConfig.enableCommunity ? _c('div', {
    staticClass: "align-self-start mt-8"
  }, [_vm._v(" Looking for community account? "), _c('router-link', {
    attrs: {
      "to": "/community/login"
    }
  }, [_vm._v("Login to community.")])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }