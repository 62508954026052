<template>
  <v-row justify="center" align="center" class="pa-0 ma-0 customer_login_page">
    <div style="position: absolute; top: 1rem; left: 1rem" class="wwwx">
      <router-link to="/">
        <img
          alt="CodeDrills Logo"
          style="position: relative; z-index: 1; width: 15rem"
          :src="logo"
        />
      </router-link>
    </div>
    <v-col cols="8" v-if="$vuetify.breakpoint.mdAndUp">
      <v-card elevation="0" class="customer_login_carousel" height="100%" dark>
        <Carousel :slides="slides" />
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      style="height: 100%"
      class="rounded-tl-xl rounded-bl-xl d-flex flex-column justify-center customer_login_card"
    >
      <Login />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import Login from '../../components/campaign/LoginForm.vue'
import Carousel from '../../components/campaign/signup/Carousel.vue'
import { getWhitelabelConfig } from '../../utils/whitelabel'

export default {
  data() {
    return {
      slides: [
        {
          image: 'campaign/login.png',
          title: '',
          subtitle: '',
        },
      ],
    }
  },
  components: {
    Login,
    Carousel,
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('recruiter', ['recruiter']),
    logo() {
      return getWhitelabelConfig().dashboardLogo
    },
  },
  created() {
    console.log('user / recruiter', this.user?.uid, this.recruiter)
    if (!!this.user && !!this.customer) {
      this.$router.push('/dashboard')
    }
  },
}
</script>
<style scoped>
.customer_login_page {
  background-color: #122333;
  padding-top: 13px;
  padding-bottom: 13px;
  height: 100%;
}

.customer_login_card {
  background-color: white;
}

.customer_login_carousel {
  background-color: #122333;
}
</style>
