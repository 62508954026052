var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-0 ma-0 customer_login_page",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "wwwx",
    staticStyle: {
      "position": "absolute",
      "top": "1rem",
      "left": "1rem"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticStyle: {
      "position": "relative",
      "z-index": "1",
      "width": "15rem"
    },
    attrs: {
      "alt": "CodeDrills Logo",
      "src": _vm.logo
    }
  })])], 1), _vm.$vuetify.breakpoint.mdAndUp ? _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', {
    staticClass: "customer_login_carousel",
    attrs: {
      "elevation": "0",
      "height": "100%",
      "dark": ""
    }
  }, [_c('Carousel', {
    attrs: {
      "slides": _vm.slides
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "rounded-tl-xl rounded-bl-xl d-flex flex-column justify-center customer_login_card",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('Login')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }